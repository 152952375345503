.table {
	color: var(--gray800);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	&__title {
		padding: 10px 16px;
		color: var(--Greyscale-Grey-800);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	&--fiat-balance {
		.tr {
			grid-template-columns: 1.3fr 0.9fr 0.9fr 0.9fr 0.9fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 8px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 24px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 24px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
			}

			&__more {
				color: var(--greyscale-grey-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&--timestamp-date {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&--timestamp-time {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 3px;
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-body {
			.tr {
				align-items: center;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}
	}

	&--wallet {
		.tr {
			align-items: center;

			.coin {
				margin-top: 0;
			}
		}

		.table-header {
			.tr {
				.td {
					@media only screen and (min-width: 1080px) {
						padding-top: 24px;
						padding-bottom: 6px;
					}
				}
			}
		}
	}

	&--transaction-history-trade,
	&--transaction-history-fiat,
	&--transaction-history-crypto,
	&--statement-history {
		.table-footer {
			border-top: 1px solid var(--greyscale-grey-50);
			padding: 20px 24px 24px 24px;

			@media only screen and (max-width: 767.98px) {
				flex-direction: column;
				gap: 16px;
			}

			.select-page {
				.select--per-page-wrapper {
					color: var(--blue600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					.select {
						width: 80px;
						height: 32px;
						border-radius: 6px;
						border: 1px solid var(--greyscale-grey-100);
						background: var(--greyscale-grey-10);

						&.active {
							border: 1px solid var(--primary-blue-200);
							background: var(--additional-white);

							.select__current-arrow {
								transform: rotate(180deg);
							}
						}
					}

					.select__current {
						padding: 0 10px;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.select__current-arrow {
						margin-left: 0;
					}

					.select__drop {
						top: unset;
						left: 0;
						min-width: 120px;
						bottom: calc(100% + 5px);
						border-radius: 6px;
						border: 1px solid var(--primary-blue-200);
						background: var(--additional-white);
						box-shadow: 0 4px 15px 0 rgba(33, 44, 99, 0.09);
						padding: 2px;

						ul > li > button {
							padding: 5px 10px;
							border-radius: 4px;
							color: var(--greyscale-grey-500);
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 160%; /* 22.4px */
							background: transparent;

							&:hover,
							&.active {
								background: var(--primary-blue-50);
								color: var(--greyscale-grey-800);
								font-weight: 500;
							}
						}
					}
				}
			}

			.pagination-block {
				padding: 0;
				border: 0;
			}

			.button-wrap {
				display: flex;
				gap: 8px;

				button {
					margin-right: 0;
				}
			}
		}
	}

	&--account-fees,
	&--account-banking,
	&--fiat-fiat {
		.td {
			padding: 10px 4px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 10px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 10px;
				}
			}

			&__wrap {
				padding: 0 8px;
			}

			&__text {
				color: var(--Greyscale-Grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;

				&--coin {
					display: flex;
					align-items: center;
					gap: 8px;

					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 8px;
						height: 30px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: center;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}
	}

	&--account-fees {
		.tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}
	}

	&--account-banking {
		.tr {
			grid-template-columns: 1fr 1fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}
	}

	&--fiat-fiat {
		.tr {
			grid-template-columns: 1fr 1fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}
	}

	&--transaction-history-trade {
		.tr {
			grid-template-columns: 1.3fr 0.7fr 0.7fr 1.4fr 1.4fr 1.4fr 0.8fr 0.8fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 4px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 24px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 24px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%; /* 25.6px */

				a {
					color: var(--blue400);
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */

					&:hover {
						color: var(--text-color-main);
					}
				}
			}

			&__more {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&--timestamp-date {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&--timestamp-time {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 3px;
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}
	}
	&--transaction-history-crypto {
		.tr {
			//grid-template-columns: 1.2fr 1fr 1fr 1fr 0.9fr 1.3fr 1.3fr 0.8fr 1.2fr 1.2fr;
			grid-template-columns: 1.2fr 1fr 1fr 1fr 0.9fr 1.3fr 1.3fr 0.8fr 1.2fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 4px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 16px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%; /* 25.6px */

				a {
					color: var(--blue400);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */

					&:hover {
						color: var(--text-color-main);
					}
				}
			}

			&__more {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&__link {
				margin-top: 4px;
				display: inline-block;
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&__status {
				border-radius: 20px;
				border: 0.8px solid transparent;
				background: transparent;
				padding: 4px 6px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				white-space: nowrap;

				&.yellow {
					text-align: center;
					white-space: wrap;
					border-color: var(--additional-warning);
					background: var(--transparent-yellow);
					color: var(--additional-warning);
				}
				&.red {
					border-color: var(--additional-error);
					background: var(--transparent-red);
					color: var(--additional-error);
				}

				&.green {
					border-color: var(--additional-green);
					background: var(--transparent-green);
					color: var(--additional-green);
				}

				&.blue {
					border-color: var(--primary-blue-400);
					background: var(--primary-blue-50);
					color: var(--primary-blue-400);
				}
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
		}
	}
	&--transaction-history-crypto_withdraw {
		.tr {
			grid-template-columns: 1.2fr 1fr .9fr 1fr 0.9fr 1.3fr 1.3fr 0.8fr 1fr 1.2fr;
			//grid-template-columns: 1.2fr 1fr 1fr 1fr 0.9fr 1.3fr 1.3fr 0.8fr 1.2fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 4px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					//padding-right: 16px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}
			&__wrap___actions {
				display: flex;
				justify-content: center;
				align-items: center;
			}


			&__text {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%; /* 25.6px */

				a {
					color: var(--blue400);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */

					&:hover {
						color: var(--text-color-main);
					}
				}
			}

			&__more {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&__link {
				margin-top: 4px;
				display: inline-block;
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&__status {
				border-radius: 20px;
				border: 0.8px solid transparent;
				background: transparent;
				padding: 4px 6px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				white-space: nowrap;

				&.yellow {
					text-align: center;
					white-space: wrap;
					border-color: var(--additional-warning);
					background: var(--transparent-yellow);
					color: var(--additional-warning);
				}
				&.red {
					border-color: var(--additional-error);
					background: var(--transparent-red);
					color: var(--additional-error);
				}

				&.green {
					border-color: var(--additional-green);
					background: var(--transparent-green);
					color: var(--additional-green);
				}

				&.blue {
					border-color: var(--primary-blue-400);
					background: var(--primary-blue-50);
					color: var(--primary-blue-400);
				}
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
		}
	}

	&--statement-history {
		min-width: 1300px;

		.tr {
			grid-template-columns: 1.3fr 1fr 1.2fr 1fr 1.1fr 1.1fr 1.1fr 1.1fr 1fr 1.4fr 1.5fr 1.2fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 2px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 16px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%; /* 25.6px */

				a {
					color: var(--blue400);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */

					&:hover {
						color: var(--text-color-main);
					}
				}
			}

			&__more {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&__link {
				margin-top: 4px;
				display: inline-block;
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&__status {
				border-radius: 20px;
				border: 0.8px solid transparent;
				background: transparent;
				padding: 4px 6px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				white-space: nowrap;

				&.yellow {
					text-align: center;
					white-space: wrap;
					border-color: var(--additional-warning);
					background: var(--transparent-yellow);
					color: var(--additional-warning);
				}

				&.red {
					border-color: var(--additional-error);
					background: var(--transparent-red);
					color: var(--additional-error);
				}

				&.green {
					border-color: var(--additional-green);
					background: var(--transparent-green);
					color: var(--additional-green);
				}
			}

			p {
				color: var(--gray800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
				@media screen and (max-width: 1199px) {
					.tooltip-note {
						transform: translate(15%, -120%);
					}

					.tooltip-note i {
						top: 100%;
						left: 10%;

						&::after {
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}
				}

				&:last-child,
				&:nth-last-child(2) {
					.tooltip-note {
						top: unset;
						bottom: calc(100% + 3px);

						i {
							top: 100%;
							&::after {
								transform: translate(-50%, -50%) rotate(45deg);
							}
						}
					}
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
		}
	}
	&--transaction-history-fiat-noBeneficiary {
		.tr {
			grid-template-columns: 0.9fr 0.8fr 0.7fr 0.8fr 0.8fr 1fr 0.6fr 1fr 1fr !important;
			//grid-template-columns: 1fr 1fr 0.9fr 0.8fr 0.8fr 1.1fr 0.6fr 1fr !important;
		}
	}
	&--transaction-history-fiat,
	&--transaction-history-fiat-noBeneficiary {
		.tr {
			grid-template-columns: 1.35fr 1.1fr 1fr 1fr 0.9fr 1.3fr 1.3fr 0.9fr 1.2fr 1.4fr;
			//grid-template-columns: 1fr 1fr 0.9fr 0.8fr 0.8fr 1.1fr 1.4fr 0.6fr 1fr;
			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 8px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 16px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%; /* 25.6px */

				a {
					color: var(--blue400);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */

					&:hover {
						color: var(--text-color-main);
					}
				}
			}

			&__more {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 2px;
			}

			&__link {
				margin-top: 4px;
				display: inline-block;
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&__status {
				border-radius: 20px;
				border: 0.8px solid transparent;
				background: transparent;
				padding: 4px 2px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				&.yellow {
					text-align: center;
					white-space: wrap;
					border-color: var(--additional-warning);
					background: var(--transparent-yellow);
					color: var(--additional-warning);
				}

				&.red {
					border-color: var(--additional-error);
					background: var(--transparent-red);
					color: var(--additional-error);
				}

				&.green {
					border-color: var(--additional-green);
					background: var(--transparent-green);
					color: var(--additional-green);
				}

				&.blue {
					border-color: var(--primary-blue-400);
					background: var(--primary-blue-50);
					color: var(--primary-blue-400);
				}
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 8px 10px;
			border-radius: 6px;
			background: var(--gray300);
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					color: var(--greyscale-grey-600);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					> p {
						border-radius: 6px;
						background: var(--greyscale-grey-10);
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
		}
	}

	.table-body {
		> div:not(:last-child) {
			.tr {
				border-bottom: 1px solid var(--gray100);
			}
		}
	}
}
