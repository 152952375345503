*[class*='--mt-0'] {
	margin-top: 0;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.px-10 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.justify-content-center {
	justify-content: center;
}

.font-weight-semibold {
	font-weight: 600;
}
